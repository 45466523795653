// import { createApp } from 'vue/dist/vue.esm-browser'
import { createApp } from 'vue/dist/vue.esm-bundler'
// import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueObserveVisibility from 'vue3-observe-visibility'

import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

// import './assets/sass/fonts.css'
import './assets/sass/index.scss'

// let app = createApp().use(VueObserveVisibility).use(Vue3Lottie)
let app = createApp(App).use(VueObserveVisibility).use(Vue3Lottie).use(router)

app.component('fullscreen-video-block', require('./components/Blocks/FullscreenVideoBlock.vue').default);
app.component('blocks', require('./components/Blocks.vue').default);
// app.component('blocks', require('./components/Blocks.js'));
app.component('page-navigation', require('./components/PageNavigation.vue').default);
app.component('page-footer', require('./components/PageFooter.vue').default);
app.component('page-navigation', require('./components/PageNavigation.vue').default);
app.component('venn-diagram', require('./components/VennDiagram.vue').default);

const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()

app.mount('#app')
// createApp(App).use(store).use(router).use(VueObserveVisibility).use(Vue3Lottie).mount('#app')
