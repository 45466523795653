<template>
    <div class="blocks">
        <template v-for="(block, i) in blocks" :key="`block-${i}`">
            <!-- Fullscreen video block -->
            <div v-if="block.acf_fc_layout == 'fullscreen_video'" class="block flex justify-content-center" :id="block.id">
                <video class="fullscreen-video" autoplay muted loop playsinline id="fullscreenVideo">
                    <source :src="block.video" type="video/mp4">
                </video>
                <!-- <h1 class="center-title">making the complicated <span>simple</span></h1> -->
                <h1 class="center-title" v-html="block.title"></h1>
                <div class="slide-right-button">
                    <a :href="`#${block.next_button_destination}`" class="action-btn"></a>
                </div>
            </div>
            <!-- / Fullscreen video block -->

            <!-- Menu block -->
            <div v-if="block.acf_fc_layout == 'menu_page'"
                class="block padded-top padded-bottom padded-left padded-right flex gap" :id="block.id">
                <div class="col-grow">
                    <div class="menu-tiles">
                        <a href="/how" class="tile" style="background-image: url('/wp-content/themes/timber-starter-theme/assets/img/how-tile.png')">
                            <h4>How</h4>
                        </a>
                    </div>
                </div>
                <div class="col-grow">
                    <div class="menu-tiles">
                        <a href="/about" class="tile" style="background-image: url('/wp-content/themes/timber-starter-theme/assets/img/about-tile.png')">
                            <h4>About</h4>
                        </a>
                    </div>
                </div>

                <!-- Third Tile - Desktop -->
                <div class="col-grow desktop-only">
                    <div class="menu-tiles tiles-stacked gap tiles-stacked-vertical">
                        <div class="tiles-top">
                            <a href="/research" class="tile" style="background-image: url('/wp-content/themes/timber-starter-theme/assets/img/research-tile.png')">
                                <h4>Research</h4>
                            </a>
                        </div>
                        <div class="tiles-bottom">
                            <div class="menu-tiles tiles-stacked gap">
                                <a href="/work" class="tile" style="background-image: url('/wp-content/themes/timber-starter-theme/assets/img/work-tile.png')">
                                    <h4>Work</h4>
                                </a>
                                <a href="/thoughts" class="tile" style="background-image: url('/wp-content/themes/timber-starter-theme/assets/img/thoughts-tile.png')">
                                    <h4>Thoughts</h4>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- / Third Tile - Desktop -->

                <!-- Third Tile - Mobile -->
                <div class="mobile-only col-grow">
                    <div class="menu-tiles tiles-stacked gap tiles-stacked-vertical">
                        <div class="tiles-top">
                            <a href="/research" class="tile" style="background-image: url('/wp-content/themes/timber-starter-theme/assets/img/research-tile.png')">
                                <h4>Research</h4>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="mobile-only col-grow">
                    <div class="menu-tiles tiles-stacked gap tiles-stacked-vertical">
                        <div class="tiles-bottom">
                            <div class="menu-tiles tiles-stacked gap">
                                <a href="/work" class="tile" style="background-image: url('/wp-content/themes/timber-starter-theme/assets/img/work-tile.png')">
                                    <h4>Work</h4>
                                </a>
                                <a href="/thoughts" class="tile" style="background-image: url('/wp-content/themes/timber-starter-theme/assets/img/thoughts-tile.png')">
                                    <h4>Thoughts</h4>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- / Third Tile - Mobile -->
            </div>
            <!-- / Menu block -->

            <!-- Image Left Bleed -->
            <div v-if="block.acf_fc_layout == 'image_left_bleed'" class="block flex" :id="block.id">
                <div class="block-left col mobile-1-3">
                    <div class="block-inner block-inner-image block-inner-image-cover block-inner-image-bleed">
                        <img :src="block.image" alt="">
                    </div>
                </div>

                <div class="block-right col mobile-2-3">
                    <div class="block-inner block-inner-has-actions">
                        <div class="block-inner-top">

                            <h1>{{ block.title }}</h1>

                            <div class="section" v-html="block.text"></div>
                        </div>
                        <div class="block-inner-bottom actions">
                            <a v-if="block.next_button_destination" :href="`#${block.next_button_destination}`"
                                class="action-btn btn-next">
                                {{ block.next_button_text }}
                            </a>
                            <div v-if="!block.next_button_destination"></div>

                            <a v-if="block.previous_button_destination" :href="`#${block.previous_button_destination}`"
                                class="action-btn btn-left">
                                <span>{{ block.previous_button_text }}</span>
                            </a>
                            <div v-if="!block.previous_button_destination"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- / Image Left Bleed -->

            <!-- Venn Diagram -->
            <div v-if="block.acf_fc_layout == 'venn'" class="block flex mobile-flex-reverse" :id="block.type">
                <div class="block-left col">
                    <div class="block-inner block-inner-has-actions">
                        <div class="block-inner-top">

                            <h1 v-if="block.type == 'challenge'">Challenge</h1>
                            <h1 v-if="block.type == 'commercial-intelligence'">Commercial Intelligence</h1>
                            <h1 v-if="block.type == 'simple'">Simple</h1>
                            <h1 v-if="block.type == 'research'">Research</h1>

                            <div class="section" v-html="block.text">
                            </div>

                            <div v-if="block.type == 'research'" class="section">

                                <!-- Research Icons -->
                                <div class="research-icons">
                                    <a href="#quantitative" class="research-icon quantitative">
                                        Quantitative
                                    </a>
                                    <a href="#qualitative" class="research-icon qualitative">
                                        Qualitative
                                    </a>
                                    <a href="#mystery-shopper" class="research-icon mystery-shopper">
                                        Mystery Shopper
                                    </a>
                                </div>
                                <!-- / Research Icons -->

                            </div>
                        </div>
                        <div v-if="block.type == 'commercial-intelligence'" class="block-inner-bottom actions">
                            <a href="#challenge" class="action-btn btn-next">
                                Challenge
                            </a>
                            <a href="#block-1" class="action-btn btn-left">
                                <span>How</span>
                            </a>
                        </div>
                        <div v-if="block.type == 'simple'" class="block-inner-bottom actions">
                            <a href="#research" class="action-btn btn-next">
                                Research
                            </a>
                            <a href="#challenge" class="action-btn btn-left">
                                <span>Challenge</span>
                            </a>
                        </div>
                        <div v-if="block.type == 'challenge'" class="block-inner-bottom actions">
                            <a href="#simple" class="action-btn btn-next">
                                Simple
                            </a>
                            <a href="#commercial-intelligence" class="action-btn btn-left">
                                <span>Commercial Intelligence</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="block-right col">
                    <div class="block-inner">

                        <venn-diagram
                            :selected="block.type == 'commercial-intelligence' ? 'ci' : block.type"></venn-diagram>

                    </div>
                </div>
            </div>
            <!-- / Venn Diagram -->

            <!-- Quantitative Research -->
            <div v-if="block.acf_fc_layout == 'quantitative_research'" class="block flex" id="quantitative">
                <div class="block-left col mobile-1-3">
                    <div class="block-inner">

                        <Vue3Lottie :animationData="quantitativeJson" />

                    </div>
                </div>
                <div class="block-right col mobile-2-3">
                    <div class="block-inner block-inner-has-actions">
                        <div class="block-inner-top">

                            <h1>Quantitative</h1>

                            <div class="section">
                                <p>
                                    In recent years, the cost of generating quantitative data from research has fallen
                                    dramatically. Whether
                                    that be from a number of cheap self-service platforms, or from buying into
                                    ‘off-the-shelf pre-written
                                    studies, getting a pile of data has never been cheaper. But that is exactly what it is,
                                    a pile of numbers.
                                    There is no value in just churning out data. Our value comes in early, we don’t want a
                                    pile of data,
                                    instead, we want to bring you on a journey where we make sure the questionnaire is given
                                    the time and
                                    space to make sure it is exactly on point. That the sample we use is exactly fit for
                                    purpose, and that
                                    data is explored thoroughly, making the most out of every brief.
                                </p>
                            </div>

                            <div class="section">

                                <!-- Research Icons -->
                                <div class="research-icons">
                                    <!-- <a href="#quantitative" class="research-icon quantitative">
                  Quantitative
                </a> -->
                                    <a href="#qualitative" class="research-icon qualitative">
                                        Qualitative
                                    </a>
                                    <a href="#mystery-shopper" class="research-icon mystery-shopper">
                                        Mystery Shopper
                                    </a>
                                </div>
                                <!-- / Research Icons -->

                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <!-- / Quantitative Research -->

            <!-- Qualitative Research -->
            <div v-if="block.acf_fc_layout == 'qualitative_research'" class="block flex" id="qualitative">
                <div class="block-left col mobile-1-3">
                    <div class="block-inner">

                        <Vue3Lottie :animationData="qualitativeJson" />

                    </div>
                </div>
                <div class="block-right col mobile-2-3">
                    <div class="block-inner block-inner-has-actions">
                        <div class="block-inner-top">

                            <h1>Qualitative</h1>

                            <div class="section">
                                <p>
                                    Getting under the skin of consumers is often where the biggest nuggets of insight come
                                    from. So much of
                                    what consumers do is unconscious and good qualitative research is the tool to dig deep
                                    and reveal these
                                    insights.
                                    Sadly, a lot of qualitative research is still undertaken using old techniques that are
                                    just not fit for
                                    purpose in today’s constantly changing and dynamic environments – we are looking at you
                                    focus groups.
                                    Instead we look at the environment we need to work in, the nature of what we want to
                                    find out and select
                                    the qualitative research tools that truly get to the heart of the audience and how they
                                    feel and think,
                                    but more importantly do so inside reality of the environment in which they are in.
                                </p>
                            </div>

                            <div class="section">

                                <!-- Research Icons -->
                                <div class="research-icons">
                                    <a href="#quantitative" class="research-icon quantitative">
                                        Quantitative
                                    </a>
                                    <!-- <a href="#qualitative" class="research-icon qualitative">
                  Qualitative
                </a> -->
                                    <a href="#mystery-shopper" class="research-icon mystery-shopper">
                                        Mystery Shopper
                                    </a>
                                </div>
                                <!-- / Research Icons -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- / Qualitative Research -->

            <!-- Mystery Shopper -->
            <div v-if="block.acf_fc_layout == 'mystery_shopper'" class="block flex" id="mystery-shopper">
                <div class="block-left col mobile-1-3">
                    <div class="block-inner">
                        <Vue3Lottie :animationData="mysteryShopperJson" />
                    </div>
                </div>
                <div class="block-right col mobile-2-3">
                    <div class="block-inner block-inner-has-actions">
                        <div class="block-inner-top">

                            <h1>Mystery Shopper</h1>

                            <div class="section">
                                <p>
                                    We don’t like mystery shopping.
                                    It doesn’t make any sense to get someone to pretend to be something they are not, put
                                    them in an alien
                                    environment and report on a created experience they can’t fully understand and expect to
                                    understand what’s
                                    worked.
                                    Far better to just be honest.
                                    Our version of this is to find a person who actually needs to be in the situation we
                                    want to test and get
                                    them to let us know how it went.
                                    Sounds simple, but we have a well-defined process for recruitment, training, observing
                                    and debriefing
                                    which allows us to look at the most complex interactions with unrivalled depth resulting
                                    in outcomes that
                                    traditional mystery shopping will never go to.
                                </p>
                            </div>

                            <div class="section">

                                <!-- Research Icons -->
                                <div class="research-icons">
                                    <a href="#quantitative" class="research-icon quantitative">
                                        Quantitative
                                    </a>
                                    <a href="#qualitative" class="research-icon qualitative">
                                        Qualitative
                                    </a>
                                    <!-- <a href="#mystery-shopper" class="research-icon mystery-shopper">
                  Mystery Shopper
                </a> -->
                                </div>
                                <!-- / Research Icons -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- / Mystery Shopper -->
        </template>
    </div>
</template>

<script>
import VennDiagram from './VennDiagram.vue';
import QuantitativeJson from '../views/lottie/Quantitative.json';
import QualitativeJson from '../views/lottie/Qualitative.json';
import MysteryShopperJson from '../views/lottie/MysteryShopper.json';

export default {
    data() {
        return {
            isScrolling: false,
            blocks: window.blocks,

            quantitativeJson: QuantitativeJson,
            qualitativeJson: QualitativeJson,
            mysteryShopperJson: MysteryShopperJson
        }
    },

    components: {
        'venn-diagram': VennDiagram
    },

    mounted() {
        this.blocks = window.blocks;

        const blocks = document.querySelector('.blocks');
        let vm = this;

        blocks.addEventListener('wheel', (ev) => {
            // blocks.scrollLeft += (ev.deltaY + ev.deltaX);
            if (ev.deltaY) {
                ev.preventDefault();

                if (vm.isScrolling) {
                    return false;
                }

                vm.isScrolling = true;

                if (ev.deltaY > 0 && ev.deltaY > ev.deltaX) {
                    blocks.scrollLeft += window.innerWidth;
                }

                if (ev.deltaY < 0 && ev.deltaY < ev.deltaX) {
                    blocks.scrollLeft -= window.innerWidth;
                }

                window.setTimeout(() => {
                    vm.isScrolling = false;
                }, 1000);
            }
        });
    }
}
</script>

<style></style>