<template>
  <div class="home blocks">

    <!-- Block -->
    <div class="block flex justify-content-center" id="block-1">
      <video class="fullscreen-video" autoplay muted loop playsinline id="fullscreenVideo">
        <source src="/video/homepage.mp4" type="video/mp4">
      </video>
      <h1 class="center-title">making the complicated <span>simple</span></h1>
      <div class="slide-right-button">
        <a href="#block-2" class="action-btn"></a>
      </div>
    </div>
    <!-- / Block -->

    <!-- Block -->
    <div class="block padded-top padded-bottom padded-left padded-right flex gap" id="block-2">
      <div class="col-grow">
        <div class="menu-tiles">
          <a href="/how" class="tile" style="background-image: url('/img/how-tile.png')">
            <h4>How</h4>
          </a>
        </div>
      </div>
      <div class="col-grow">
        <div class="menu-tiles">
          <a href="/about" class="tile" style="background-image: url('/img/about-tile.png')">
            <h4>About</h4>
          </a>
        </div>
      </div>

      <!-- Third Tile - Desktop -->
      <div class="col-grow desktop-only">
        <div class="menu-tiles tiles-stacked gap tiles-stacked-vertical">
          <div class="tiles-top">
            <a href="/research" class="tile" style="background-image: url('/img/research-tile.png')">
              <h4>Research</h4>
            </a>
          </div>
          <div class="tiles-bottom">
            <div class="menu-tiles tiles-stacked gap">
              <a href="/work" class="tile" style="background-image: url('/img/work-tile.png')">
                <h4>Work</h4>
              </a>
              <a href="/thoughts" class="tile" style="background-image: url('/img/thoughts-tile.png')">
                <h4>Thoughts</h4>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- / Third Tile - Desktop -->

      <!-- Third Tile - Mobile -->
      <div class="mobile-only col-grow">
        <div class="menu-tiles tiles-stacked gap tiles-stacked-vertical">
          <div class="tiles-top">
            <a href="/research" class="tile" style="background-image: url('/img/research-tile.png')">
              <h4>Research</h4>
            </a>
          </div>
        </div>
      </div>

      <div class="mobile-only col-grow">
        <div class="menu-tiles tiles-stacked gap tiles-stacked-vertical">
          <div class="tiles-bottom">
            <div class="menu-tiles tiles-stacked gap">
              <a href="/work" class="tile" style="background-image: url('/img/work-tile.png')">
                <h4>Work</h4>
              </a>
              <a href="/thoughts" class="tile" style="background-image: url('/img/thoughts-tile.png')">
                <h4>Thoughts</h4>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- / Third Tile - Mobile -->
    </div>
    <!-- / Block -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isScrolling: false
    }
  },

  mounted() {
    const blocks = document.querySelector('.blocks');
    let vm = this;

    blocks.addEventListener('wheel', (ev) => {
      // blocks.scrollLeft += (ev.deltaY + ev.deltaX);
      if (ev.deltaY) {
        ev.preventDefault();

        if (vm.isScrolling) {
          return false;
        }

        vm.isScrolling = true;

        if (ev.deltaY > 0 && ev.deltaY > ev.deltaX) {
          blocks.scrollLeft += window.innerWidth;
        }

        if (ev.deltaY < 0 && ev.deltaY < ev.deltaX) {
          blocks.scrollLeft -= window.innerWidth;
        }

        window.setTimeout(() => {
          vm.isScrolling = false;
        }, 1000);
      }
    });
  }
}
</script>