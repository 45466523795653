<template>
  <div class="ragdoll-container page-footer"  :class="{'floating': isFloating}">
    <div class="left">
        © Ragdoll Research Ltd. 2023. All rights reserved. <a href="/terms-and-conditions">Terms and conditions of use.</a>
    </div>
    <div class="right">
      <a href="#">
        <img src="/wp-content/themes/timber-starter-theme/assets/img/social/facebook.svg" alt="Facebook">
      </a>
      <a href="#">
        <img src="/wp-content/themes/timber-starter-theme/assets/img/social/twitter.svg" alt="Twitter">
      </a>
      <a href="#">
        <img src="/wp-content/themes/timber-starter-theme/assets/img/social/instagram.svg" alt="Instagram">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  // props: ['isFloating'],

  computed: {
    isFloating() {
      return this.$route.name == 'home';
    }
  }
}
</script>

<style>

</style>