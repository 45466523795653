<template>
  <div class="ragdoll-container page-navigation"  :class="{'floating': isFloating}">
    <!-- Menu Bar -->
    <div class="page-menu-bar">
      <div class="left">
        <a class="logo" href="/" rel="home">
          <span class="sr-only">Ragdoll Research logo</span>
          <span class="rag">rag</span><span class="doll">doll</span>
        </a>
      </div>
      <div class="right">
        <div class="menu-toggle" :class="{ 'active': menuExpanded }" @click.prevent="toggleMenu">
          <span class="top"></span>
          <span class="middle"></span>
          <span class="bottom"></span>
        </div>
      </div>
    </div>
    <!-- / Menu Bar -->

    <!-- Expandable Sidebar -->
    <div class="sidebar" :class="{ 'expanded': menuExpanded }">

      <!-- Navigation -->
      <nav class="sidebar-navigation">
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li class="has-children">
            <a href="/how">How</a>
            <span @click.prevent="showSubmenu('how')" class="more" :class="{ 'show': submenuToShow == 'how' }">+</span>
            <ul class="sub-menu" :class="{ 'show': submenuToShow == 'how' }">
              <li>
                <a href="/how#commercial-intelligence">Commercial Intelligence</a>
              </li>
              <li>
                <a href="/how#challenge">Challenge</a>
              </li>
              <li>
                <a href="/how#simple">Simple</a>
              </li>
            </ul>
          </li>
          <li class="has-children">
            <a href="/about">About</a>
            <span @click.prevent="showSubmenu('about')" class="more"
              :class="{ 'show': submenuToShow == 'about' }">+</span>
            <ul class="sub-menu" :class="{ 'show': submenuToShow == 'about' }">
              <li>
                <a href="/about#boule">Boule</a>
              </li>
              <li>
                <a href="/how#charity">Charity</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="/about">Research</a>
          </li>
          <li>
            <a href="/work">Work</a>
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
        </ul>
      </nav>
      <!-- / Navigation -->

      <!-- Become a panelist -->
      <div class="become-a-panelist">
        <h3>Become a panelist</h3>
        <p>Take our survey to help us understand the people we work with, so we can find the most suitable research
          opportunities for you.</p>
        <a href="" class="action-btn btn-next">Take over survey</a>
      </div>
      <!-- / Become a panelist -->

      <!-- Sidebar Footer -->
      <!-- <div class="sidebar-footer">
        <div class="links">
          <a href="#">Privacy Policy</a>
          <a href="#">Cookie Policy</a>
        </div>
        <div class="info">
          &copy; Ragdoll Research 2022. All rights reserved.
        </div>
      </div> -->
      <!-- / Sidebar Footer -->

    </div>
    <!-- / Expandable Sidebar -->
  </div>
</template>

<script>
export default {
  // props: ['isFloating'],

  data() {
    return {
      menuExpanded: false,
      submenuToShow: null
    }
  },

  computed: {
    isFloating() {
      return this.$route.name == 'home';
    }
  },

  methods: {
    toggleMenu() {
      this.menuExpanded = !this.menuExpanded;
    },

    showSubmenu(menu) {
      if (menu == this.submenuToShow) {
        this.submenuToShow = null;
      } else if (menu != this.submenuToShow) {
        this.submenuToShow = menu;
      }
    }
  }
}
</script>

<style></style>